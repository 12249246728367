<template>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/contact.svg" alt="Contact"><span class="sub">お問い合わせ</span></h1>
        </div>
        <section class="form container-pad">
          <div class="copy-fukidashi -contact"><div class="comment"><div class="inner"><span><img src="@/assets/image/contact/fukidashi.svg" alt=""></span><span><img src="@/assets/image/contact/image.jpg" alt=""></span></div></div></div>

          <form id="vueform" v-on:submit.prevent="onSubmit" ref="form" novalidate >
          <dl class="form-content">
            <dt>お名前<span class="form-required">*</span></dt>
            <dd><input type="text" class="form-text" id="name" name="your-name">
              <span class="wpcf7-form-control-wrap your-name"></span></dd>
            <dt>会社名</dt>
            <dd><input type="text" class="form-text" id="corp" name="your-corp">
              <span class="wpcf7-form-control-wrap your-corp"></span></dd>
            <dt>E-mail<span class="form-required">*</span></dt>
            <dd><input type="email" class="form-text" id="email" name="your-email">
              <span class="wpcf7-form-control-wrap your-email"></span></dd>
            <dt>TEL</dt>
            <dd><input type="tel" class="form-text" id="tel" name="your-tel">
              <span class="wpcf7-form-control-wrap your-tel"></span></dd>
            <dt>お問い合わせ内容<span class="form-required" id="message" name="your-message">*</span></dt>
            <dd><textarea class="form-textarea" id="message" name="your-message"></textarea>
              <span class="wpcf7-form-control-wrap your-message"></span></dd>
          </dl>

          <div class="nav-form">
            <input type="submit" value="送信する" class="button-link" />
            <input type="hidden" name="_wpcf7" v-model="formId" />
            <input type="hidden" name="_wpcf7_unit_tag" v-model="unitTag" />
            <input type="hidden" name="_wpcf7_locale" value="ja" />
          </div>
          </form>

        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted, ref } from 'vue'
import axios from "axios"
const API_HOST = process.env.VUE_APP_APIRESTHOST;
//http://oneb/c/wp-json/contact-form-7/v1/contact-forms/44/feedback

export default {
  data(){
    return {
      //formId: 44,
    }
  },
  setup() {
    const formId = ref(44)
    const unitTag = "wpcf7-f" + formId.value + "-o1"
    const form = ref(null)

    onMounted(() => {
      //onSubmit()
    })
    onUpdated(() => {
    })
    onUnmounted(() => {
    })

    const onSubmit = () => {

      let myform = form.value
      const formData = new FormData(myform);


      let message = document.createElement('p');
      let inputs = myform.querySelectorAll("p input, p textarea")

      let textDanger = document.getElementsByClassName('text-danger')
      if(textDanger.length > 0){
        for (let i = 0; textDanger.length; i++) {
          //console.log(textDanger[i])
          textDanger[i].innerText  = ''
          textDanger[i].remove('text-danger')
        }
      }

      let alertDanger = document.getElementsByClassName('alert-danger')
      if(alertDanger.length > 0){
        for (let i = 0; alertDanger.length; i++) {
          alertDanger[i].remove()
        }
      }

      axios.post(
        '//' + API_HOST + '/c/wp-json/contact-form-7/v1/contact-forms/44/feedback',
        formData
      ).then( response => {

        //console.log('then')

        switch(response.data.status) {
          case "validation_failed":
            //console.log('validation_failed')
            for(let i in response.data.invalid_fields) {
              let error = document.querySelector(response.data.invalid_fields[i].into)
              error.textContent = response.data.invalid_fields[i].message
              error.classList.add("text-danger")
            }
            break;
          case "mail_sent":
            //console.log('mail_sent')
            message.textContent = response.data.message;
            message.classList.add("alert");
            message.classList.add("alert-success");

            myform.append(message);

            inputs = myform.querySelectorAll("input.form-text,textarea.form-textarea")

            if(inputs){
              for(let i=0; i<inputs.length; i++) {
                inputs[i].value = "";
              }
            }

            setTimeout(function(){
              message.remove()
            },3000)
            break;

          default:{
            //console.log('default')
                let dangerElem = document.getElementsByClassName('danger')
                if( dangerElem.length > 0 ){
                   for (let i = 0; dangerElem.length; i++) {
                    dangerElem[i].remove()
                  }
                }

                message.textContent = response.data.message;
                message.classList.add("danger").add("alert-danger")

                myform.append(message);
                setTimeout(function(){
                  message.remove()
                },3000)
              }
        }

      })
      .catch(function(/*error*/){
        //console.log('error')

        message.textContent = "エラーが発生しました。";
        message.classList.add("alert");
        message.classList.add("alert-danger");

        myform.append(message);
      })
      .finally(function(){
        /*
        if(overlay = document.querySelector(".bg-load-overlay")) {
          overlay.parentNode.removeChild(overlay);
        }*/
      });
    }

    return{
      formId,
      unitTag,
      form,
      onSubmit
    }
  },

}
</script>

<style lang="scss">
.wpcf7-form-control-wrap{
  color:#ff1493;
}
.alert{
  text-align:center;
  color:#fff;
  background-color:#ff1493;
  border-radius:6px;
  padding:10px 0;
  position:relative;
  &:before{
    content:'';
    border:12px solid transparent;
    border-bottom: 14px solid #ff1493;
    position:absolute;
    left:50%;
    top: -26px;
    margin-left:-6px;
  }
}
@keyframes fadeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}
</style>